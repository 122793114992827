<template>
    <div>
        <div>
            <div class="top_div">
                <div class="left">
                    <div class="btn1" @click="huitui">返回</div>
                    <div class="btn1" @click="dialogVisible = true">
                          上传
                    </div>
                    <div class="btn1" @click="insert_folder">新建</div>
                    <div class="btn1" @click="xiazai()">下载</div>
                    <div class="btn2" @click="oss_delete_files()">批量删除</div>
                    <div class="btn2"@click="dialogTableVisible_zhuanyi = true; zhuanyis()">批量移动</div>
                </div>
                <div class="right">  <el-input size="medium" v-model="files" placeholder="搜索您的文件" @input="handle_input"></el-input>  </div>
            </div>
            <div class="wenjian_name">
                <!-- 全部文件 -->
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item v-for="(item,index) in bread_crumbs" :key="this" > <span @click="handle_to_bread(item,index)">{{item.folder_name}}</span> </el-breadcrumb-item>
                    <!-- <el-breadcrumb-item>活动管理</el-breadcrumb-item>
                    <el-breadcrumb-item>活动列表</el-breadcrumb-item>
                    <el-breadcrumb-item>活动详情</el-breadcrumb-item> -->
                </el-breadcrumb>
            </div>
            <div>

            <el-table
                ref="multipleTable"
                :data="sys_folder_list"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange1">
                <!-- @row-click="handleToson" -->

                <el-table-column
                type="selection"
                width="55">
                </el-table-column>

                <el-table-column
                label="企业盘"
                width="300">
                <template slot-scope="scope">
                    <div @click="handleToson(scope.row)">   <i class="el-icon-folder"></i> {{ scope.row.folder_name }}  </div>
                </template>
                </el-table-column>

                <el-table-column
                label="大小"
                align="center"
                width="500">
                <template slot-scope="scope">
                    <div >  {{ scope.row.size }}  </div>
                </template>
                </el-table-column>

                <el-table-column
                prop="address"
                label="修改日期"
                align="center"
                width="300"
                show-overflow-tooltip>
                </el-table-column>

                <el-table-column
                align="center"
                width="500"
                show-overflow-tooltip>
                <template slot-scope="scope" >
                    <div class="caozuo">
                        <i v-if="scope.row.folder_type!=1&&!scope.row.file_id" @click="delete_folder(scope.row)" class="el-icon-delete"></i>
                        <i v-if="scope.row.file_id" @click="oss_delete_file(scope.row)" class="el-icon-delete"></i>
                        <i class="el-icon-folder-opened" @click="dialogTableVisible_zhuanyi = true; zhuanyi(scope.row)"></i>
                    </div>
                </template>
                </el-table-column>

            </el-table>

            <el-table
                ref="multipleTable"
                :data="file_list"
                tooltip-effect="dark"
                style="width: 100%"

                @selection-change="handleSelectionChange">

                <el-table-column
                type="selection"
                width="55">
                </el-table-column>

                <el-table-column
                label="个人盘"
                width="300">
                <template slot-scope="scope">
                    <div @click="handleToson(scope.row)">
                        <i class="el-icon-folder"></i> {{ scope.row.folder_name }}
                    </div>
                </template>
                </el-table-column>

                <el-table-column
                prop="name"
                label="大小"
                align="center"
                width="500">
                </el-table-column>

                <el-table-column
                prop="address"
                label="修改日期"
                align="center"
                width="300"
                show-overflow-tooltip>
                </el-table-column>

                <el-table-column
                align="center"
                width="500"
                show-overflow-tooltip>
                <template slot-scope="scope" >
                    <div class="caozuo">
                        <i v-if="!scope.row.file_id" @click="delete_folder(scope.row)" class="el-icon-delete"></i>
                        <i v-if="scope.row.file_id" @click="oss_delete_file(scope.row)" class="el-icon-delete"></i>
                        <i class="el-icon-folder-opened"></i>
                    </div>

                </template>
                </el-table-column>

            </el-table>
            </div>
        </div>

        <!-- 上传文件 -->
        <!-- <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
            <el-upload class="upload-demo" ref="upload" action='' :multiple="false" :auto-upload="false">
              <h4 class="button" style="margin-left:100px">上传文件</h4>
            </el-upload>


        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false;handle_oss_upload_file()">确 定</el-button>
        </span>
        </el-dialog> -->



<el-dialog title="上传文件" :visible.sync="dialogVisible"   >
 <el-row>
    <el-col :span="8"  >
        <div class="UPBG">
            <el-upload
            ref="upload1"
            drag
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple>
            <i class="el-icon-plus" style="color:#FC4353;font-size:50px"></i>
            </el-upload>
        </div>
        <p style="text-align: center;margin-top:20px">点击或拖拽此处上传文件</p>
    </el-col>



    <el-col :span="8"  >
        <div class="UPBG">
            <el-upload
            ref="upload2"
            drag
            action="https://jsonplaceholder.typicode.com/posts/"
            multiple>
                <i class="el-icon-video-play" style="color:#FC4353;font-size:50px"></i>
            </el-upload>
        </div>
        <p style="text-align: center;margin-top:20px">点击或拖拽此处上传视频</p>
    </el-col>

    <el-col :span="8"  >
        <div class="UPBG">
            <el-upload
                ref="upload3"
                drag
                action="https://jsonplaceholder.typicode.com/posts/"
                multiple>
                <i class="el-icon-folder-opened" style="color:#FC4353;font-size:50px"></i>
            </el-upload>
        </div>
        <p style="text-align: center;margin-top:20px">点击或拖拽此处上传文件</p>
    </el-col>
</el-row>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false; handle_oss_upload_file()">确 定</el-button>
    </div>
</el-dialog>



        <el-dialog  title="文件地址" :visible.sync="dialogTableVisible_zhuanyi">
            <div class="zhunayi_box">
                <div>
                    <el-table ref="zhuan_qy" :data="zhuan_qy_file" @selection-change="handlezhuan_change">
                        <el-table-column type="selection"    width="55"></el-table-column>
                        <el-table-column property="date" label="企业盘" width="150">
                            <template slot-scope="scope">
                                <div @click="handleToson_zhuan(scope.row)">
                                    <i class="el-icon-folder"></i> {{ scope.row.folder_name }}
                                </div>
                            </template>
                        </el-table-column>
                        <!-- <el-table-column property="name" label="姓名" width="200"></el-table-column> -->
                        <!-- <el-table-column property="address" label="地址"></el-table-column> -->
                    </el-table>
                </div>
                <div>
                    <el-table ref="zhuan_gr" :data="zhuan_gr_file"   @selection-change="handlezhuan_change">
                        <el-table-column type="selection"    width="55"></el-table-column>
                        <el-table-column property="date" label="个人盘" width="150">
                            <template slot-scope="scope">
                                <div @click="handleToson_zhuan(scope.row)">
                                    <i class="el-icon-folder"></i> {{ scope.row.folder_name }}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogTableVisible_zhuanyi = false">取 消</el-button>
                <el-button type="primary" @click="dialogTableVisible_zhuanyi = false;zhuanyi_wenjan()">确 定</el-button>
            </span>
        </el-dialog>


    </div>
</template>

<script>

  import axios from 'axios'

import { query_user_folder_list,insert_folder, delete_folder,oss_delete_file,oss_delete_files,move_file,query_user_folder_auth_list } from '../../api/store'


export default {
    data(){
        return{
            files:'', // 搜索的文件名
            tableData: [{
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
                }, {
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
                }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }],
            file_list:[{
                active: "1",
                ent_id: "public",
                folder_id: "1",
                folder_name: "目标",
                folder_type: "1",
                module_id: "1",
                module_name: "目标",
                parent_id: "0",
            }], // 个人盘
            // 面包屑
            bread_crumbs:[{
                folder_name:'全部文件',
                folder_id:'0'
            }],

            sys_folder_list:[{
                active: "1",
                ent_id: "public",
                folder_id: "1",
                folder_name: "目标",
                folder_type: "1",
                module_id: "1",
                module_name: "目标",
                parent_id: "0",
            }], // 企业盘

            // 新建文件夹的父id
            new_folder_id:[],
            lay_type :'', // 判断是 企业盘 个人盘

            // 上传文件
            dialogVisible:false,
            // 上传文件的 父 id
            upload_parent_id:'',
            // 批量删除文件
            url_s:'',

            // 转移文件
            dialogTableVisible_zhuanyi:false,
            zhuan_qy_file:[], // 转移企业盘
            zhuan_gr_file:[], // 转移个人盘
            zhuan_wenjan_list:[], // 转移文件的选中列表 只有一个
            onely_zhuan_file:'', // 需要转移的一个文件
            zhuan_files:[], // 多个需要转移的文件
            zhuan_files_gr:[], // 多个需要转移的文件

            // 权限设置
            quanxian_list:[],
            module_id_qx:'',


        }
    },
    created(){
        this.query_user_folder_auth_list()
    },
    mounted(){
        this.query_user_folder_list()
        this.query_user_folder_list_personage()
    },

    methods:{
        // 返回按钮
        huitui(){
        this.$router.push({
          path: '/cloudStorage'
        })
        },

        // 查询文件列表
        query_user_folder_list(parent_id){
            query_user_folder_list({data:{
                ent_id: this.$store.state.ent_id,
                user_id:this.$store.state.user_id,
                parent_id: parent_id?parent_id:'0'
            }}).then((res)=>{
                console.log('文件列表',res);
                if (res.code == '200') {
                    let sys_folder_list = res.body.data.sys_folder_list // 默认文件夹
                    let folder_list = res.body.data.folder_list // 个人新建的文件夹
                    let file_list = res.body.data.file_list // 上传的文件
                    console.log('企业盘',sys_folder_list);

                    for (let a in file_list) {
                        file_list[a].folder_name = file_list[a].file_name
                    }

                   let new_folder_list= folder_list.concat(file_list) // 合并文件
                   let new_sys_folder_list= sys_folder_list.concat(file_list) // 合并文件


                    if (sys_folder_list.length != '0') {
                        this.sys_folder_list = new_sys_folder_list
                    }else{
                        this.sys_folder_list = new_folder_list
                    }

                }
            })
        },
        // 个人盘
        query_user_folder_list_personage(parent_id){
            query_user_folder_list({data:{
                            ent_id: this.$store.state.ent_id,
                            user_id:this.$store.state.user_id,
                            parent_id: parent_id?parent_id:'0'
                        }}).then((res)=>{
                            console.log('个人盘文件列表',res);
                            if (res.code == '200') {
                                let sys_folder_list = res.body.data.sys_folder_list // 默认文件夹
                                let folder_list = res.body.data.folder_list // 个人新建的文件夹
                                let file_list = res.body.data.file_list // 上传的文件
                                console.log('个人盘',sys_folder_list);
                                let file_list_geren = []
                                for (let a in file_list) {
                                    file_list[a].folder_name = file_list[a].file_name
                                    if (file_list[a].lay_type == '2') {
                                        file_list_geren.push(file_list[a])
                                    }
                                }

                                let new_folder_list= folder_list.concat(file_list_geren) // 合并文件

                                this.file_list = new_folder_list
                            }
                        })
        },

        handle_input(val){
            console.log(val);
        },

        // 企业盘的多选事件
        handleSelectionChange1(val){
            console.log('========',val);
            let folder_id = []
            let parent_id = []
            let url = []
            for (let a in val) {
                folder_id.push(val[a].folder_id)
                parent_id.push(val[a].parent_id)
                if (val[a].url) {
                    url.push(val[a].url)
                }
            }
            console.log('批量删除文件',url);
            this.new_folder_id = folder_id
            // this.upload_parent_id = parent_id
            this.url_s = url
            let zhuan_files = []
            this.zhuan_files = zhuan_files.concat(val) // 转移文件多选
            console.log(this.zhuan_files);

        },

        // 个人盘的多选事件
        handleSelectionChange(val){
            console.log('========',val);
            let folder_id = []
            let parent_id = []
            let url = []
            for (let a in val) {
                folder_id.push(val[a].folder_id)
                parent_id.push(val[a].parent_id)
                if (val[a].url) {
                    url.push(val[a].url)
                }
            }
            console.log('批量删除文件',url);
            this.new_folder_id = folder_id
            // this.upload_parent_id = parent_id
            this.url_s = url

            let zhuan_files_gr = []
            this.zhuan_files_gr =  zhuan_files_gr.concat(val) // 转移文件多选

        },

        // 查询子级的文件夹
        handleToson(row){
            console.log(row,'=======');
            if (row.file_id) {

            }else{
                let parent_id = row.folder_id // 父级的id
                // let lay_type = row.lay_type
                // 面包屑
                let bread = this.bread_crumbs
                bread.push({
                    folder_name:row.folder_name,
                    lay_type: row.lay_type,
                    folder_id:row.folder_id
                })
                if (row.folder_id) {
                    this.module_id_qx = row.folder_id
                }

                this.upload_parent_id = parent_id
                this.lay_type =  row.lay_type // 判断是 企业盘 个人盘
                //
                if (row.lay_type=='1') {
                    this.query_user_folder_list(parent_id)
                }
                if (row.lay_type=='2') {
                    this.query_user_folder_list_personage(parent_id)
                }
            }

        },

        // 新建文件
        insert_folder(){
            console.log('===========',this.lay_type);
            if (this.new_folder_id.length < 2 ) {
                this.$prompt('请输入文件夹名', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    }).then(({ value }) => {
                        this.insert_folder_two(value)
                    })
            }else{
                this.$message({
                    type: 'success',
                    message: '请选择一个文件夹'
                });
            }

        },
        // 新建文件夹
        insert_folder_two(folder_name){
            // let parent_id = this.new_folder_id.join(',')
            let parent_id = this.upload_parent_id
            insert_folder({data:{
                ent_id: this.$store.state.ent_id,
                user_id:this.$store.state.user_id,
                parent_id:parent_id?parent_id:'0',
                folder_name:folder_name, //文件名
                lay_type:this.lay_type?this.lay_type:'2'
            }}).then((res)=>{
                console.log('新建文件夹',res);
                if (res.code == '200') {
                    // this.query_user_folder_list()
                    // this.query_user_folder_list_personage()
                    let parent_id = this.bread_crumbs[this.bread_crumbs.length-1].folder_id
                    if (this.lay_type=='1') {
                        this.query_user_folder_list(parent_id)
                    }else if(this.lay_type == '2'){
                        this.query_user_folder_list_personage(parent_id)
                    }else{
                        this.query_user_folder_list(parent_id)
                        this.query_user_folder_list_personage(parent_id)
                    }
                }

            })
        },
        // 删除文件 夹
        delete_folder(row){
                console.log(row);

            let quanxian_list = this.quanxian_list

            for (let a in quanxian_list) {
                if (quanxian_list[a].module_id == this.module_id_qx && quanxian_list[a].auth == '3') {
                    let folder_id = row.folder_id
                    this.$confirm('此操作将永久删除该文件和文件中的内容, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        // 删除
                        delete_folder({data:{
                            ent_id: this.$store.state.ent_id,
                            // user_id:this.$store.state.user_id,
                            folder_id:folder_id,
                        }}).then((res)=>{
                            console.log('删除文件',res);
                            if (res.code=='200') {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功!'
                                });
                                this.zhuan_query_user_folder_list()

                                // this.query_user_folder_list()
                                // this.query_user_folder_list_personage()
                                let parent_id = this.bread_crumbs[this.bread_crumbs.length-1].folder_id
                                if (this.lay_type=='1') {
                                    this.query_user_folder_list(parent_id)
                                }else if(this.lay_type == '2'){
                                    this.query_user_folder_list_personage(parent_id)
                                }else{
                                    this.query_user_folder_list(parent_id)
                                    this.query_user_folder_list_personage(parent_id)
                                }
                            }
                        })
                    }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                    });
                }else{
                    this.$message({
                        type: 'success',
                        message: '您当前没有权限'
                    });
                }
            }


        },

        // 删除文件
        oss_delete_file(row){
            console.log(row);
            let url = row.url

            let quanxian_list = this.quanxian_list


            for (let a in quanxian_list) {
                if (quanxian_list[a].module_id == this.module_id_qx && quanxian_list[a].auth == '3') {


                    this.$confirm('此操作将永久删除该文件和文件中的内容, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                    }).then(() => {
                        // 删除
                        oss_delete_file({data:{
                            ent_id: this.$store.state.ent_id,
                            // user_id:this.$store.state.user_id,
                            url:url,
                        }}).then((res)=>{
                            console.log('删除文件',res);
                            if (res.code=='200') {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功!'
                                });
                                let parent_id = this.bread_crumbs[this.bread_crumbs.length-1].folder_id
                                if (this.lay_type=='1') {
                                    this.query_user_folder_list(parent_id)
                                }else if(this.lay_type == '2'){
                                    this.query_user_folder_list_personage(parent_id)
                                }else{
                                    this.query_user_folder_list(parent_id)
                                    this.query_user_folder_list_personage(parent_id)
                                }
                            }
                        })
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除'
                        });
                    });
                }else{
                    this.$message({
                        type: 'success',
                        message: '您当前没有权限'
                    });
                }
            }




        },

        // 批量删除
        oss_delete_files(){
            let url = this.url_s.join(',')

            let quanxian_list = this.quanxian_list


            for (let a in quanxian_list) {
                if (quanxian_list[a].module_id == this.module_id_qx && quanxian_list[a].auth == '3') {
                    this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                                confirmButtonText: '确定',
                                cancelButtonText: '取消',
                                type: 'warning'
                                }).then(() => {
                                    // 删除
                                    oss_delete_file({data:{
                                        ent_id: this.$store.state.ent_id,
                                        // user_id:this.$store.state.user_id,
                                        url:url,
                                    }}).then((res)=>{
                                        console.log('删除文件',res);
                                        if (res.code=='200') {
                                            this.$message({
                                                type: 'success',
                                                message: '删除成功!'
                                            });
                                            this.zhuan_query_user_folder_list()
                                            // let parent_id = this.bread_crumbs[this.bread_crumbs.length-1].folder_id
                                            // this.query_user_folder_list(parent_id)
                                            let parent_id = this.bread_crumbs[this.bread_crumbs.length-1].folder_id
                                            if (this.lay_type=='1') {
                                                this.query_user_folder_list(parent_id)
                                            }else if(this.lay_type == '2'){
                                                this.query_user_folder_list_personage(parent_id)
                                            }else{
                                                this.query_user_folder_list(parent_id)
                                                this.query_user_folder_list_personage(parent_id)
                                            }
                                        }
                                    })
                                }).catch(() => {
                                this.$message({
                                    type: 'info',
                                    message: '已取消删除'
                                });
                                });
                }else{
                    this.$message({
                        type: 'success',
                        message: '您当前没有权限'
                    });
                }
            }


        },

        // 上传文件
        handle_oss_upload_file(){
            // console.log('上传文件+上传文件', this.$refs.upload.uploadFiles)
            // let date1 = []

            let quanxian_list = this.quanxian_list

            for (let a in quanxian_list) {
                if (quanxian_list[a].module_id == this.module_id_qx && quanxian_list[a].auth == '1') {
                    console.log('上传文件+上传文件', this.$refs.upload1.uploadFiles)
                    if (this.$refs.upload1.uploadFiles) {
                        console.log('上传文件+上传文件', this.$refs.upload1.uploadFiles)
                        // date1.concat(this.$refs.upload1.uploadFiles)
                        let date = this.$refs.upload1.uploadFiles
                        let parent_id = this.upload_parent_id
                        let list = []
                        let shibai = []
                        let fasong = []
                        for (let i = 0; i < date.length; i++) {
                            list.push(date[i].raw)
                        }


                        for (let i = 0; i < list.length; i++) {
                        let fileObj = list[i]
                        let type = list[i].name.split('.')[1]
                        let form = new FormData() // FormData 对象
                        form.append('file', fileObj) // 文件对象  'upload'是后台接收的参数名
                        form.append('file_type', type) // 文件后缀  'upload'是后台接收的参数名
                        form.append('parent_id', parent_id) // 父  id
                        form.append('ent_id', this.$store.state.ent_id)
                        form.append('user_id', sessionStorage.getItem('user_id'))
                        form.append('lay_type', this.lay_type?this.lay_type:'2')
                        axios({
                        method: 'post',
                        url:'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/oss_upload_file',
                        data: form,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                        }).then(res => {
                        console.log(res);
                        let code = res.data.code
                        if (code == '200') {
                            let parent_id = this.bread_crumbs[this.bread_crumbs.length-1].folder_id
                            if (this.lay_type=='1') {
                                this.query_user_folder_list(parent_id)
                            }else if (this.lay_type=='2') {
                                this.query_user_folder_list_personage(parent_id)
                            }else{
                                this.query_user_folder_list_personage(parent_id)
                            }
                        }

                        if (res.code =='19000') {
                            this.$message({
                                type: 'success',
                                message: '云盘空间不足，无法上传文件'
                            });
                        }

                        })

                    }
                    }
                    if (this.$refs.upload2.uploadFiles) {
                        console.log('上传文件+上传文件', this.$refs.upload2.uploadFiles)
                        // date1.concat(this.$refs.upload2.uploadFiles)
                        let date = this.$refs.upload2.uploadFiles
                        let parent_id = this.upload_parent_id
                        let list = []
                        let shibai = []
                        let fasong = []
                        for (let i = 0; i < date.length; i++) {
                            list.push(date[i].raw)
                        }


                        for (let i = 0; i < list.length; i++) {
                        let fileObj = list[i]
                        let type = list[i].name.split('.')[1]
                        let form = new FormData() // FormData 对象
                        form.append('file', fileObj) // 文件对象  'upload'是后台接收的参数名
                        form.append('file_type', type) // 文件后缀  'upload'是后台接收的参数名
                        form.append('parent_id', parent_id) // 父  id
                        form.append('ent_id', this.$store.state.ent_id)
                        form.append('user_id', sessionStorage.getItem('user_id'))
                        form.append('lay_type', this.lay_type?this.lay_type:'2')
                        axios({
                        method: 'post',
                        url:'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/oss_upload_file',
                        data: form,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                        }).then(res => {
                        console.log(res);
                        let code = res.data.code
                        if (code == '200') {
                            let parent_id = this.bread_crumbs[this.bread_crumbs.length-1].folder_id
                            if (this.lay_type=='1') {
                                this.query_user_folder_list(parent_id)
                            }else if (this.lay_type=='2') {
                                this.query_user_folder_list_personage(parent_id)
                            }else{
                                this.query_user_folder_list_personage(parent_id)
                            }
                        }


                        if (res.code =='19000') {
                            this.$message({
                                type: 'success',
                                message: '云盘空间不足，无法上传文件'
                            });
                        }

                        })

                    }
                    }
                    if (this.$refs.upload3.uploadFiles) {
                        console.log('上传文件+上传文件', this.$refs.upload3.uploadFiles)
                        // date1.concat(this.$refs.upload3.uploadFiles)
                        let date = this.$refs.upload3.uploadFiles
                        let parent_id = this.upload_parent_id
                        let list = []
                        let shibai = []
                        let fasong = []
                        for (let i = 0; i < date.length; i++) {
                            list.push(date[i].raw)
                        }


                        for (let i = 0; i < list.length; i++) {
                        let fileObj = list[i]
                        let type = list[i].name.split('.')[1]
                        let form = new FormData() // FormData 对象
                        form.append('file', fileObj) // 文件对象  'upload'是后台接收的参数名
                        form.append('file_type', type) // 文件后缀  'upload'是后台接收的参数名
                        form.append('parent_id', parent_id) // 父  id
                        form.append('ent_id', this.$store.state.ent_id)
                        form.append('user_id', sessionStorage.getItem('user_id'))
                        form.append('lay_type', this.lay_type?this.lay_type:'2')
                        axios({
                        method: 'post',
                        url:'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/oss_upload_file',
                        data: form,
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                        }).then(res => {
                        console.log(res);
                        let code = res.data.code
                        if (code == '200') {
                            let parent_id = this.bread_crumbs[this.bread_crumbs.length-1].folder_id
                            if (this.lay_type=='1') {
                                this.query_user_folder_list(parent_id)
                            }else if (this.lay_type=='2') {
                                this.query_user_folder_list_personage(parent_id)
                            }else{
                                this.query_user_folder_list_personage(parent_id)
                            }
                        }


                        if (res.code =='19000') {
                            this.$message({
                                type: 'success',
                                message: '云盘空间不足，无法上传文件'
                            });
                        }

                        })

                    }
                    }
                    // console.log(date1);

                //     const date = this.$refs.upload.uploadFiles
                //     const parent_id = this.upload_parent_id
                //     const list = []
                //     const shibai = []
                //     const fasong = []
                //     for (let i = 0; i < date.length; i++) {
                //         list.push(date[i].raw)
                //     }


                //     for (let i = 0; i < list.length; i++) {
                //     const fileObj = list[i]
                //     const type = list[i].name.split('.')[1]
                //     const form = new FormData() // FormData 对象
                //     form.append('file', fileObj) // 文件对象  'upload'是后台接收的参数名
                //     form.append('file_type', type) // 文件后缀  'upload'是后台接收的参数名
                //     form.append('parent_id', parent_id) // 父  id
                //     form.append('ent_id', this.$store.state.ent_id)
                //     form.append('user_id', sessionStorage.getItem('user_id'))
                //     form.append('lay_type', this.lay_type?this.lay_type:'2')
                //     axios({
                //       method: 'post',
                //       url:'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/oss_upload_file',
                //       data: form,
                //       headers: {
                //         'Content-Type': 'application/x-www-form-urlencoded'
                //       }
                //     }).then(res => {
                //       console.log(res);
                //       let code = res.data.code
                //       if (code == '200') {
                //         let parent_id = this.bread_crumbs[this.bread_crumbs.length-1].folder_id
                //         if (this.lay_type=='1') {
                //             this.query_user_folder_list(parent_id)
                //         }else if (this.lay_type=='2') {
                //             this.query_user_folder_list_personage(parent_id)
                //         }else{
                //             this.query_user_folder_list_personage(parent_id)
                //         }
                //       }
                //     })

                //   }
                }else{
                    this.$message({
                        type: 'success',
                        message: '您当前没有权限'
                    });
                }
            }
        },

        // 下载 文件
        xiazai(){
            let url_s = this.url_s
            console.log('xxxxxxxxxxxxxxxxxxxxxx',url_s);



            for (let a in quanxian_list) {
                if (quanxian_list[a].module_id == this.module_id_qx && quanxian_list[a].auth == '2') {
                    for (let a = 0; a < url_s.length; a++) {
                        window.open(
                            url_s[a]
                        )
                    }
                }
            }

        },

        // 关闭对话框
        handleClose(done) {
            done();
        },

        // 面包屑跳转
        handle_to_bread(item,index){
            console.log(item,index);
            let parent_id = item.folder_id
            if (item.lay_type=='1') {
                this.query_user_folder_list(parent_id)
            }else if (item.lay_type=='2') {
                this.query_user_folder_list_personage(parent_id)
            }else{
                this.query_user_folder_list(parent_id)
                this.query_user_folder_list_personage(parent_id)
            }

            this.bread_crumbs.splice((index+1),this.bread_crumbs.length-(index+1))

        },

        // --- --- -- 转移
        zhuanyi(row){
            console.log('需要转移的文件',row);
            if (row.file_id) {
                this.onely_zhuan_file = row.file_id
            }
            if (row.folder_id) {
                this.onely_zhuan_file = row.folder_id
            }
            this.zhuan_query_user_folder_list('0')
            this.zhan_query_user_folder_list_personage('0')
        },
       zhuanyis(row){
            this.zhuan_query_user_folder_list('0')
            this.zhan_query_user_folder_list_personage('0')
        },

        // 查询文件列表
        zhuan_query_user_folder_list(parent_id){
            query_user_folder_list({data:{
                ent_id: this.$store.state.ent_id,
                user_id:this.$store.state.user_id,
                parent_id: parent_id?parent_id:'0'
            }}).then((res)=>{
                console.log('文件列表',res);
                if (res.code == '200') {
                    let sys_folder_list = res.body.data.sys_folder_list // 默认文件夹
                    let folder_list = res.body.data.folder_list // 个人新建的文件夹
                    let file_list = res.body.data.file_list // 上传的文件
                    console.log('企业盘',sys_folder_list);
                    console.log('企业盘---个人新建的文件夹',folder_list);


                    if (sys_folder_list.length != '0') {
                        this.zhuan_qy_file = sys_folder_list
                    }else{
                        this.zhuan_qy_file = folder_list
                    }

                }
            })
        },
        // 个人盘
        zhan_query_user_folder_list_personage(parent_id){
            query_user_folder_list({data:{
                            ent_id: this.$store.state.ent_id,
                            user_id:this.$store.state.user_id,
                            parent_id: parent_id?parent_id:'0'
                        }}).then((res)=>{
                            console.log('个人盘文件列表',res);
                            if (res.code == '200') {
                                let sys_folder_list = res.body.data.sys_folder_list // 默认文件夹
                                let folder_list = res.body.data.folder_list // 个人新建的文件夹
                                let file_list = res.body.data.file_list // 上传的文件
                                console.log('个人盘',folder_list);
                                this.zhuan_gr_file = folder_list
                            }
                        })
        },

        // 查询子级的文件夹
        handleToson_zhuan(row){
            console.log(row,'=======');
            if (row.file_id) {

            }else{
                let parent_id = row.folder_id // 父级的id
                // let lay_type = row.lay_type
                // 面包屑
                // let bread = this.bread_crumbs
                // bread.push({
                //     folder_name:row.folder_name,
                //     lay_type: row.lay_type,
                //     folder_id:row.folder_id
                // })
                // this.upload_parent_id_zhuan = parent_id
                if (row.lay_type=='1') {
                    this.zhuan_query_user_folder_list(parent_id)
                }
                if (row.lay_type=='2') {
                    this.zhan_query_user_folder_list_personage(parent_id)
                }
            }

        },
        handlezhuan_change(val){
            console.log('转移',val);
            this.zhuan_wenjan_list = val
        },
        // 确认转移
        zhuanyi_wenjan(){
            console.log('转移', this.zhuan_wenjan_list);

            console.log('多个文件', this.zhuan_files,'|||',this.zhuan_files_gr); //
            let file_list = this.zhuan_files.concat(this.zhuan_files_gr)
            let file_id_s = []
            for (let b in file_list) {
                if (file_list[b].folder_id) {
                    file_id_s.push(file_list[b].folder_id)
                }
                if (file_list[b].file_id) {
                    file_id_s.push(file_list[b].file_id)
                }
            }
            console.log(file_id_s);
            let zhuan_wenjan_list = this.zhuan_wenjan_list
            let  file_id = this.onely_zhuan_file
            if (zhuan_wenjan_list.length == '1') {
                move_file({data:{
                   ent_id: this.$store.state.ent_id,
                    file_id:file_id_s.length >0?file_id_s.join(','):file_id, // 文件的id
                    to_folder_id:this.zhuan_wenjan_list[0].folder_id // 移动到目标文件的id
                }}).then((res)=>{
                    console.log(res)
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '转移成功'
                        });

                        let parent_id = this.bread_crumbs[this.bread_crumbs.length-1].folder_id
                        if (this.lay_type=='1') {
                            this.query_user_folder_list(parent_id)
                        }else if(this.lay_type == '2'){
                            this.query_user_folder_list_personage(parent_id)
                        }else{
                            this.query_user_folder_list(parent_id)
                            this.query_user_folder_list_personage(parent_id)
                        }

                    }

                })
            }else{
                this.$message({
                    type: 'success',
                    message: '请选择一个文件夹'
                });
            }
        },

        // -------------------------------权限查询
    query_user_folder_auth_list(){
        query_user_folder_auth_list({data:{
          ent_id: this.$store.state.ent_id,
          user_id:this.$store.state.user_id,
        }}).then((res)=>{
          console.log('权限',res);
          if (res.code == '200') {
            let qx_list = res.body.data
            this.quanxian_list = qx_list

          }
        })
      },



    }
}
</script>

<style lang="scss" scoped>
.top_div{
    width: 100%;
    height: 50px;
    // border: 1px solid;
    display: flex;
    justify-content: space-between;
    .left{
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 50%;
        height: 100%;
        .btn1{
            width: 100px;
            height: 30px;
            border: 1px solid #FF404E;
            color: #FF404E;
            border-radius: 6px;
            font-size: 16px;
            text-align: center;
            line-height: 30px;
            margin-right: 20px;
        }
        .btn2{
            width: 100px;
            height: 30px;
            border: 1px solid #FF404E;
            background-color: #FF404E;
            color: #fff;
            border-radius: 6px;
            font-size: 16px;
            text-align: center;
            display: block;
            line-height: 30px;
            margin-right: 20px;
        }
    }
    .right{
        width: 30%;
        margin: 0 5% 0 0;
        line-height: 50px;
    }

}
.wenjian_name{
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 16px;
}
.title{
    width: 100%;
    height: 50px;
    // display: flex;
    > div:nth-child(1){
        width: 20%;
        text-align: center;
        float: left;
    }
    > div:nth-child(2){
        width: 30%;
        text-align: center;
        float: left;

    }
    > div:nth-child(3){
        width: 20%;
        text-align: center;
        float: left;

    }
}
.clearfix::before, .clearfix::after {
    content: "";
    height: 0;
    line-height: 0;
    display: block;
    visibility: hidden;
    clear: both;
}
.caozuo{
    >i{
        font-size:24px ;
        margin: 0 20px 0 0;
    }
}

.button{
    width: 200px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    border-radius: 6px;
    background-color: #FF404E;
}

.zhunayi_box {
    display: flex;
    justify-content: space-around;
    > div{
        width: 50%;
    }
}

// 上传文件
.UPBG{
  width: 200px;
  height: 200px;
  border: 3px dashed #FC4353;
  border-radius: 5px;
  text-align: center;
  line-height: 200px;
  vertical-align: middle;

}

</style>
<style>
.UPBG .el-upload-dragger{
  width: 100%;
  border: none;
      /* height: 200px; */
    /* line-height: 200px; */
}

.CloudBG .el-dialog{
  width: 700px;
}
</style>